var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",staticStyle:{"background-color":"#efeff1"}},[_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table",attrs:{"data":_vm.list,"size":"mini","border":"","fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.completionDate'),"prop":"date","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.completion_date, '{y}-{m}-{d}')))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.property'),"sortable":"custom","prop":"property.full_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row.property, ['full_code'])))])]}}])}),_c('el-table-column',{attrs:{"align":"center","sortable":"custom","prop":"client.translations.first_name,client.translations.last_name","label":_vm.$t('table.client')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row.client)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.notes')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getSubText(row.notes, 100)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"dateCreated","label":_vm.$t('table.dateCreated'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.date_created)))])]}}])})],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }