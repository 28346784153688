import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getVisits = (data: any) =>
  request({
    method: 'post',
    url: '/api/visits/getVisits',
    data
  })

export const getVisitsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/visits/getVisitsCount',
    data
  })

export const getVisit = (params: any) =>
  request({
    method: 'get',
    url: '/api/visits/getVisit',
    params
  })

export const saveVisit = (data: any) =>
  request({
    method: 'post',
    url: '/api/visits/saveVisit',
    data
  })

export const saveVisitBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/visits/saveVisitBulk',
    data
  })

export const deleteVisit = (params: any) =>
  request({
    method: 'get',
    url: '/api/visits/deleteVisit',
    params
  })

export const deleteVisitBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/visits/deleteVisitBulk',
    data
  })

export const exportVisits = (visits: any) => {
  const fields = [
    {
      key: 'property',
      type: 'custom',
      parse: (item: any) => {
        if (item.property) {
          return item.property.full_code
        }
        return ''
      }
    },
    {
      key: 'user',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user)
      }
    },
    {
      key: 'client',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user)
      }
    },
    {
      key: 'notes'
    },
    {
      key: 'date_created',
      type: 'timestamp'
    }
  ]

  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, visits),
    'visit-list'
  )
}
